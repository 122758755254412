import { DialogContent, DialogOverlay } from '@reach/dialog';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import * as React from 'react';
import { HiChevronDown, HiX, HiOutlineDocumentDownload } from 'react-icons/hi';

import config from '../../config.json';
import {
  BackgroundTexture,
  BoardOfDirectors,
  BusinessCardIcon,
  HourglassIcon,
  Layout,
  SEO,
  SideBySide,
} from '../components';
import { ContactFormWithMap } from '../components/contact-form-with-map';
import { HeadingWithIcon } from '../components/heading-with-icon';
import { BookIcon } from '../components/vectors/book';
import { ChequeIcon } from '../components/vectors/cheque';
import { useGraphQLAbout, useLazyLoad, useGraphQL } from '../hooks';

function AboutPage(): React.ReactElement {
  return (
    <Layout>
      <SEO title="About Us" />
      <WhereItAllBegan />
      <OpeningDay />
      <WarYears />
      <AnnualReports />
      <ClubDirectors />
      <ClubGrants />
      <NewImage />
      <ClubPolicy />
      <ContactFormWithMap background="blue" />
    </Layout>
  );
}

function SkipLink({ id }) {
  return (
    <p className="relative mt-4">
      <Link to={id} className="inline-flex items-center justify-center button">
        <span className="sr-only">Skip to next section</span>
        <span className="text-white">
          <HiChevronDown aria-hidden className="text-2xl" />
        </span>
      </Link>
    </p>
  );
}

function WhereItAllBegan(): React.ReactElement {
  const { whereItBegan1, whereItBegan2, whereItBegan3 } = useGraphQLAbout();

  return (
    <div
      id="where_it_all_began"
      className="relative pt-12 pb-0 xs:py-12 scroll-margin-top-24"
    >
      <BackgroundTexture insetTop="half" insetBottom="half" overflowBottom />
      <div className="relative">
        <SideBySide
          sideComponent={
            <div className="grid h-full grid-cols-5 gap-4 xs:gap-8">
              <div className="col-span-5 bg-blue-dark">
                <GatsbyImage
                  image={whereItBegan1.childImageSharp.gatsbyImageData}
                  className="flex-1 h-full"
                  alt=" "
                />
              </div>
              <div className="col-span-3 bg-blue-dark">
                <GatsbyImage
                  image={whereItBegan2.childImageSharp.gatsbyImageData}
                  className="flex-1 h-full"
                  alt=""
                />
              </div>
              <div className="col-span-2 bg-blue-dark">
                <GatsbyImage
                  image={whereItBegan3.childImageSharp.gatsbyImageData}
                  className="flex-1 h-full"
                  alt=""
                />
              </div>
            </div>
          }
        >
          <div className="flex flex-col justify-center w-full p-4 pt-8 mx-auto max-w-prose">
            <HeadingWithIcon>
              <HeadingWithIcon.Heading>
                Where It
                <br />
                All Began
              </HeadingWithIcon.Heading>
              <HeadingWithIcon.Icon hidden>
                <HourglassIcon className="w-16" />
              </HeadingWithIcon.Icon>
            </HeadingWithIcon>
            <div className="mt-5 prose">
              <p>
                Following representation from local residents in 1937 the
                Municipal Council decided to investigate the possibility of
                constructing the town’s first lawn bowling green. The Lands
                Department of the day in agreement with the Maritime Services
                Board approved the release of part of the Pilot Station
                recreation reserve.
              </p>
              <p>
                This area became the site of the Club first green and original
                Club house, the cost of developing the green was a total sum of
                470 pounds ($940). The cost of the excavation was 20 pounds,
                construction of the green 350 pounds, windmill, and piping and
                tank 50 pounds, fencing 40 pounds and contingencies 10 pounds.
                Work was commenced in July 1937 and completed in September 1937.
              </p>
              <p>
                A meeting was held on the 16th December 1937 for the formation
                of the Port Macquarie Bowling Club (men only); Mr. H.T. O’Neil
                was elected the first President. Bowls were played on the new
                green for the first time on the 22nd of December 1937.
              </p>
            </div>
            <SkipLink id="#opening_day" />
          </div>
        </SideBySide>
      </div>
    </div>
  );
}

function OpeningDay(): React.ReactElement {
  const { whereItBegan4 } = useGraphQLAbout();

  return (
    <div id="opening_day" className="relative pt-24 scroll-margin-top-24">
      <div className="relative">
        <SideBySide
          sideComponent={
            <GatsbyImage
              image={whereItBegan4.childImageSharp.gatsbyImageData}
              className="flex-1 h-full"
              alt=""
            />
          }
          isReversed={false}
        >
          <div className="relative">
            <BackgroundTexture />
            <div className="relative w-full px-4 py-12 mx-auto mt-2 prose sm:px-6 lg:px-8">
              <p>
                A grand opening day was held on Saturday 23rd April, 1938, the
                first annual general meeting of the newly formed club was held
                on the 14th June 1939, with a total of 26 bowlers in attendance.
                The club concluded its first year with a membership of 43 and a
                turnover of 420 pounds and 11 shillings ($841.10).
              </p>
              <p>
                The site of the green and clubhouse (shared with the tennis
                club) was originally rented from the council for the sum of 1
                pound ($2) per week including maintenance. At a committee
                meeting on the 25th March 1938 the newly formed club took over
                custody of the green for an annual fee of three guineas ($6.60)
                payable half yearly.
              </p>
              <p>
                Number two green was constructed in 1953, number three green in
                1956, number four in 1970 and number five in 1985. Until 1957
                the club was dependent on two wells for water supplies with
                watering of the greens undertaken by hand held hoses. Town water
                was connected to the club in 1957.
              </p>
              <SkipLink id="#war_years" />
            </div>
          </div>
        </SideBySide>
      </div>
    </div>
  );
}

function WarYears(): React.ReactElement {
  const { whereItBegan5 } = useGraphQLAbout();

  return (
    <div
      id="war_years"
      className="relative pt-0 pb-12 mt-12 xs:py-12 scroll-margin-top-24"
    >
      <BackgroundTexture insetBottom="full" overflowBottom />
      <div className="relative">
        <SideBySide
          sideComponent={
            <GatsbyImage
              image={whereItBegan5.childImageSharp.gatsbyImageData}
              className="flex-1 h-full"
              alt=""
            />
          }
        >
          <div className="w-full mx-auto mt-2 prose lg:pt-24">
            <p>
              During the 1939/45 war years, club membership remained stagnant at
              under thirty bowlers, in 1954 a meeting was held with a number of
              women and an agreement reached to form the Women’s Bowling Club.
              By 1960 the combined membership of the club had risen to three
              hundred (approximately two hundred men and one hundred women).
            </p>
            <p>
              In 1950 the first poker machines arrives in Port Macquarie, one
              sixpenny (5c) machine which was owned by the Golf Club, used at
              weekends and lent to the Bowling Club and RSL Club during the week
              days if required.
            </p>
            <p>
              Our first poker machine was purchased in 1958; we now have in
              excess of one hundred and forty machines.
            </p>
            <p>
              During 1972 new government legislation required all licensed clubs
              to be incorporated; as a result our club became registered as a
              company under the title of Port Macquarie Bowling Club Limited.
            </p>
          </div>
        </SideBySide>
      </div>
    </div>
  );
}

type AnnualReportsModalProps = {
  close: () => void;
  showDialog: boolean;
};

function AnnualReportsModal({
  close,
  showDialog,
}: AnnualReportsModalProps): React.ReactElement {
  return (
    <DialogOverlay
      isOpen={showDialog}
      onDismiss={close}
      className="fixed inset-0 z-40 overflow-y-auto"
    >
      <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-black opacity-30" />
        </div>
        {/* This element is to trick the browser into centering the modal contents. */}
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        />
        <DialogContent
          style={{ margin: 0 }}
          className="inline-block px-4 pt-5 pb-4 m-0 overflow-hidden text-left align-bottom transition-all transform bg-white shadow-xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
            <button
              type="button"
              onClick={close}
              className="text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2"
            >
              <span className="sr-only">Close</span>
              <HiX aria-hidden className="w-6 h-6" />
            </button>
          </div>
          <div className="mt-3 text-center sm:mt-0 sm:text-left">
            <div className="prose">
              <h3 className="heading-2" id="modal-headline">
                Annual Reports
              </h3>
              <ul>
                {[
                  {
                    title: '2018 Part 1',
                    link:
                      'https://www.dropbox.com/s/ko9kytmycudl2hk/PMCBC%20Ltd%202018%20Financial%20Report%20-%20Signed.pdf?dl=0',
                  },
                  {
                    title: '2018 Part 2',
                    link:
                      'https://www.dropbox.com/s/47v5haijfcrfsq6/2018%20Annual%20Report%20D2.pdf?dl=0',
                  },
                  {
                    title: '2019',
                    link:
                      'https://www.dropbox.com/s/bf1w4vdigna4csx/Port%20City%202019%20Annual%20Report%20webfile.pdf?dl=0',
                  },
                  {
                    title: '2020',
                    link:
                      'https://drive.google.com/file/d/15v1R_UQjKlRmZyvFEkalbx0YSsgrMInl/view?usp=sharing',
                  },
                  {
                    title: '2021',
                    link:
                      'https://e.issuu.com/embed.html?d=pcb_annual_report_v10&u=portcitybowlingclub',
                  },
                  {
                    title: '2022',
                    link:
                      'https://drive.google.com/file/d/1n7Is3uI40foSaTmg9Ao_Taio3u67BSy6/view?usp=sharing',
                  },
                  {
                    title: '2023',
                    link:
                      'https://drive.google.com/file/d/1voO3LaHe6mGfcCuSw-EmhjGaf6MonRvW/view?usp=sharing',
                  },
                ].map(({ title, link }) => (
                  <li key={title}>
                    <a href={link} target="_blank" rel="noopener noreferrer">
                      Annual Report for {title}
                    </a>
                  </li>
                ))}
              </ul>
              <p>
                Please contact us on{' '}
                <a href={`tel:${config.phone}`}>{config.phone}</a> or{' '}
                <a href={`mailto:${config.email}`}>{config.email}</a> to request
                a year not listed here.
              </p>
            </div>
          </div>
        </DialogContent>
      </div>
    </DialogOverlay>
  );
}

function AnnualReports(): React.ReactElement {
  const {
    containerRef,
    srcRef,
    isLoaded,
    handleIsLoaded,
    Spinner,
  } = useLazyLoad();

  const [showDialog, setShowDialog] = React.useState(false);
  const open = () => setShowDialog(true);
  const close = () => setShowDialog(false);
  return (
    <div id="annual_reports" className="py-12 scroll-margin-top-24">
      <SideBySide
        isReversed={false}
        sideComponent={
          <div
            ref={containerRef}
            className="relative flex aspect-w-16 aspect-h-9 bg-blue-dark"
          >
            <iframe
              ref={srcRef}
              onLoad={handleIsLoaded}
              aria-hidden
              tabIndex={0}
              title={`${config.title} Google Maps location`}
              data-src="//e.issuu.com/embed.html?d=pcb_annual_report_2024_web&u=portcitybowlingclub"
              frameBorder="0"
              className="flex-1"
            />
            {isLoaded ? null : <Spinner />}
          </div>
        }
      >
        <div className="flex h-full py-8 bg-blue-light text-blue-dark">
          <div className="flex flex-col justify-center w-full p-4 pt-8 mx-auto max-w-prose">
            <HeadingWithIcon>
              <HeadingWithIcon.Heading>
                Annual <br />
                Reports
              </HeadingWithIcon.Heading>
              <HeadingWithIcon.Icon hidden>
                <BookIcon />
              </HeadingWithIcon.Icon>
            </HeadingWithIcon>
            <div className="mt-4 prose">
              <p>Here is our 2024 Annual Report and Financial Statement.</p>
              <p>
                If you require a hard copy of this document, please see our
                friendly team at the Club’s reception.
              </p>
            </div>
            <p className="mt-4">
              <a
                href="/Constitution_adopted_13_September_2023.pdf"
                target="_blank"
                rel="noopener noreferrer"
                className="inline-flex items-center justify-center px-8 py-2 text-base font-medium uppercase transition duration-150 ease-in-out bg-white border border-blue-dark text-blue-dark hover:bg-white"
              >
                <HiOutlineDocumentDownload
                  aria-hidden
                  className="mr-1 text-2xl"
                />{' '}
                Club Constitution
              </a>
            </p>
            <p className="mt-4">
              <button
                type="button"
                onClick={open}
                className="inline-flex items-center justify-center w-56 button"
              >
                See Previous Reports
              </button>
            </p>
          </div>
        </div>
      </SideBySide>
      <AnnualReportsModal close={close} showDialog={showDialog} />
    </div>
  );
}

function ClubDirectors(): React.ReactElement {
  return (
    <div id="club_directors" className="relative py-12 scroll-margin-top-24">
      <BackgroundTexture insetBottom="full" overflowBottom />
      <div className="relative">
        <BoardOfDirectors>
          <div className="flex flex-col justify-center w-full p-4 pt-8 mx-auto lg:mt-12 max-w-prose">
            <h2 className="heading-2 heading-accent">Club Directors</h2>
            <div className="mt-5 prose">
              <p>
                <span className="font-semibold text-md">
                  Port City Bowling Club Board of Directors:
                </span>
                <br />
                Christopher King - Chairman
                <br />
                Mick Hayes - Deputy Chairman
                <br />
                Jeff Pattinson - Director
                <br />
                Wayne Evans - Director
                <br />
                Mick Garrihy - Director
                <br />
                Ross Langsford - Director
                <br />
                Jock McDonald - Director
              </p>
            </div>
          </div>
        </BoardOfDirectors>
      </div>
    </div>
  );
}

function NewImage(): React.ReactElement {
  const {
    clubGrants1,
    clubGrants2,
    newImage,
    agm2Notice,
    noticeOfElection,
    clubGrantsNowOpen,
  } = useGraphQL();

  return (
    <div className="relative flex flex-wrap w-full px-4 mx-auto space-y-6 md:space-y-0 max-w-screen-2xl sm:px-6 lg:px-8">
      <div className="w-full">
        <a
          href="https://www.clubgrants.com.au/find-your-local-grant-round?round=471100"
          target="_blank"
        >
          <GatsbyImage
            image={clubGrantsNowOpen.childImageSharp.gatsbyImageData}
            className="flex-1"
            alt="AGM"
            objectFit="contain"
          />
        </a>
      </div>
    </div>
  );
}
function ClubGrants(): React.ReactElement {
  const { clubGrants1, clubGrants2 } = useGraphQLAbout();

  return (
    <div id="club_grants" className="py-12 scroll-margin-top-24">
      <SideBySide
        isReversed={false}
        sideComponent={
          <div className="grid flex-1 grid-cols-6 gap-8">
            <div className="col-span-6">
              <GatsbyImage
                image={clubGrants1.childImageSharp.gatsbyImageData}
                className="flex-1"
                alt=""
              />
              <GatsbyImage
                image={clubGrants2.childImageSharp.gatsbyImageData}
                className="flex-1"
                alt=""
              />
            </div>
          </div>
        }
      >
        <div className="flex flex-col justify-center flex-1 py-8 bg-blue-light">
          <div className="flex flex-col justify-center w-full p-4 pt-8 mx-auto max-w-prose">
            <HeadingWithIcon>
              <HeadingWithIcon.Heading>
                Club
                <br />
                Grants
              </HeadingWithIcon.Heading>
              <HeadingWithIcon.Icon hidden>
                <ChequeIcon />
              </HeadingWithIcon.Icon>
            </HeadingWithIcon>
            <div className="mt-5 prose prose-on-dark">
              <p>
                <span className="text-lg font-semibold uppercase text-blue-dark">
                  Club Grants Funding
                </span>
                <br />
                If you would like to submit a request for sponsorship, please
                complete the appropriate application form below. If you are
                unsure of which form to complete, please contact us on{' '}
                <a href={`tel:${config.phone}`} className="inline-block">
                  {config.phone}
                </a>
                .
              </p>
              <p>
                All applications received are assessed by our Board of Directors
                at their monthly board meeting, and need to be submitted for
                processing by the third Monday of each month.
              </p>
              <p>
                Applicants will be advised of the status of their sponsorship
                request in the fortnight following the board meeting which is
                held on the last Monday of the month.
              </p>
              <p>
                <span className="text-lg font-semibold uppercase text-blue-dark">
                  Category 1 Criteria
                </span>
                <br />
                Expenditure on specific community welfare and social services,
                community development, community health services and employment
                assistance activities.{' '}
                <a
                  href="https://www.clubgrants.com.au/find-your-local-grant-round?round=179591"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Click here to apply or read more
                </a>
                .
              </p>
              <p>
                <span className="text-lg font-semibold uppercase text-blue-dark">
                  Category 2 Criteria
                </span>
                <br />
                Expenditure on other community development and support services
                (eg. traditional areas of club expenditure, such as support for
                sport and recreation groups).
              </p>
              <ul>
                <li>
                  <a
                    href="https://www.clubgrants.com.au/find-your-local-grant-round?round=321702"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Category 2
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.clubgrants.com.au/find-your-local-grant-round?round=322560"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Category 2 (In-Kind)
                  </a>
                </li>
              </ul>
              <p>
                <span className="text-lg font-semibold uppercase text-blue-dark">
                  Port City Bowling Club Past Community Projects
                </span>
              </p>
              <ul>
                <li>
                  <a
                    href="/PCBC_Actual_ClubGRANTS_2019_Expenditure.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Club Grants 2019
                  </a>
                </li>
                <li>
                  <a
                    href="/PCBC_Actual_ClubGRANTS_2020_Expenditure.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Club Grants 2020
                  </a>
                </li>
                <li>
                  <a
                    href="/PCBC_Actual_ClubGRANTS_2021_Expenditure.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Club Grants 2021
                  </a>
                </li>
                <li>
                  <a
                    href="/PCBC_Actual_ClubGRANTS_2022_Expenditure.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Club Grants 2022
                  </a>
                </li>
                <li>
                  <a
                    href="/PCBC_Actual_ClubGRANTS_2023_Expenditure.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Club Grants 2023
                  </a>
                </li>
                <li>
                  <a
                    href="/PCBC_Actual_ClubGRANTS_2024_Expenditure.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Club Grants 2024
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </SideBySide>
    </div>
  );
}

function ClubPolicy(): React.ReactElement {
  const { clubPolicy } = useGraphQLAbout();
  return (
    <div id="club_policies" className="relative py-12 scroll-margin-top-24">
      <BackgroundTexture insetBottom="full" overflowBottom />
      <div className="relative">
        <SideBySide
          sideComponent={
            <GatsbyImage
              image={clubPolicy.childImageSharp.gatsbyImageData}
              className="flex-1 h-full"
              alt=""
            />
          }
        >
          <div className="flex h-full text-blue-dark">
            <div className="flex flex-col justify-center w-full p-4 mx-auto lg:mt-12 max-w-prose">
              <HeadingWithIcon>
                <HeadingWithIcon.Heading>
                  Club <br />
                  Policy
                </HeadingWithIcon.Heading>
                <HeadingWithIcon.Icon hidden>
                  <BusinessCardIcon />
                </HeadingWithIcon.Icon>
              </HeadingWithIcon>
              <div className="mt-3 prose">
                <p className="font-medium">
                  Interested in reading our Club Policies?
                  <br />
                  Click attachment below
                </p>
              </div>
              <p className="mt-5">
                <a
                  href="/club-policies-2022.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-flex items-center justify-center px-8 py-2 text-base font-medium uppercase transition duration-150 ease-in-out bg-white border border-blue-dark text-blue-dark hover:bg-white"
                >
                  Download Club Policies PDF
                </a>
              </p>
              {/* <div className="mt-3 prose">
                <p className="font-medium">
                  Click below to read our ASIC auditor consent notice
                </p>
              </div> */}
              <p className="mt-5">
                <a
                  href="/auditor-notice.jpg"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-flex items-center justify-center px-8 py-2 text-base font-medium uppercase transition duration-150 ease-in-out bg-white border border-blue-dark text-blue-dark hover:bg-white"
                >
                  View ASIC Auditor Consent Notice
                </a>
              </p>
            </div>
          </div>
        </SideBySide>
      </div>
    </div>
  );
}

export { AboutPage as default };
